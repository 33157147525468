(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("_"));
	else if(typeof define === 'function' && define.amd)
		define("PlaceTree", ["React", "_"], factory);
	else if(typeof exports === 'object')
		exports["PlaceTree"] = factory(require("React"), require("_"));
	else
		root["PlaceTree"] = factory(root["React"], root["_"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__4__) {
return 