import React from "react";
import "./index.less";
import { Set } from "immutable";
import * as _ from "lodash";

const Tree = Loader.loadBaseComponent("Tree");
const IconFont = Loader.loadBaseComponent("IconFont");
const SearchInput = Loader.loadBaseComponent("SearchInput");

@Decorator.businessProvider("place")
@Decorator.businessObserver({ place: ["placeTreeData"] })
class PlaceTree extends React.Component {
  constructor(props) {
    super(props);
    const { expandedKeys, place } = this.props;
    this.state = {
      keyword: null,
      expandedKeys: expandedKeys || place.placeTreeData[0] ? [place.placeTreeData[0].id] : [],
      treeData: place.placeTreeData
    };
  }
  onChange = value => {
    const { place } = this.props;
    let expandedKeys = [];
    if (!value) {
      expandedKeys = [place.placeTreeData[0].id];
    } else {
      let keys = [];
      const orgs = place.placeArray.filter(v => v.name.indexOf(value) > -1);
      orgs.forEach(v => {
        let ids = place.queryPlaceIdsForParentId(v.id).map(v => v.id);
        keys = [].concat(keys, ids);
      });
      expandedKeys = [...new Set([...keys])];
    }
    this.setState({
      expandedKeys,
      keyword: value
    });
  };
  onExpand = expandedKeys => {
    this.setState({
      expandedKeys
    });
  };
  renderSuffix = node => {
    const { showCount, renderSuffix } = this.props;
    return (
      <span className="tree-node-suffix">
        {showCount && (
          <>
            <i className="online-count">{node.deviceCount.onlineCount}/</i>
            <i className="count">{node.deviceCount.count}</i>
          </>
        )}
        {renderSuffix && renderSuffix(node)}
      </span>
    );
  };
  render() {
    const {
      place,
      className = "",
      title = "行政区划",
      inputPlaceholder = "请输入组织名称",
      disableCheckbox = false,
      hasTitle = false,
      hasSearch = false,
      renderHeader,
      treeNodeClass,
      activeKey,
      treeData,
      ...props
    } = this.props;
    const { expandedKeys } = this.state;
    let treeDatas = treeData || this.state.treeData;
    return (
      <div className={`place-tree resource-select-tree ${className}`}>
        {hasTitle && (
          <div className="title-part">
            <span>{title}</span>
            {hasSearch && <SearchInput className="input-keword" placeholder={inputPlaceholder} onChange={this.onChange} />}
          </div>
        )}
        {renderHeader && renderHeader()}
        <Tree
          {...props}
          treeData={treeDatas}
          onExpand={this.onExpand}
          selectedKeys={activeKey}
          expandedKeys={expandedKeys}
          treeNodeProps={{
            treeNodeIcon: node => (
              <IconFont type={`${node.id === treeDatas[0].id ? "icon-S_Tree_index" : "icon-S_Tree_Group"}`} />
            ),
            nameKey: "areaName",
            keyword: this.state.keyword,
            suffix: this.renderSuffix,
            treeNodeClass: treeNodeClass,
            disableCheckbox: disableCheckbox
          }}
        />
      </div>
    );
  }
}

export default PlaceTree;
